.header {
  position: absolute;
  top: 0;
  left: 0;
  height: 96px;
  width: 100%;
  background-color: transparent;
  z-index: 10;

  & .links {
    border-bottom: 1px solid rgba($white, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $white;
    height: 96px;

    & .logo {
      display: flex;
      align-items: center;

      & #menu-icon {
        margin-right: 42px;
        display: none;
      }
    }

    & ul {
      height: 96px;
      display: flex;
      align-items: center;

      & li {
        &:not(:last-child) {
          margin-right: 32px;
        }

        & a {
          color: $white;

          &:hover {
            color: $chocolate;
          }
        }
      }

      & h6 {
        margin: 0;
        font-size: 13px;
      }
    }

    & img:hover {
      cursor: pointer;
    }
  }

  & .menu {
    background-color: rgba($black, 0.75);
    height: 100vh;
    position: absolute;
    top: 100%;
    width: 100vw;
    display: none;
    z-index: 10;

    & .categories {
      margin: 0;
      padding-top: 130px;
      background-color: $white;
      height: 350px;

      & .links {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;

        & .category {
          height: 165px; //
          padding-bottom: 22px;

          & .category-img {
            width: 90%;
          }

          & h6 {
            color: $black;
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .header {
    & .links {
      & .logo {
        & #menu-icon {
          margin-right: 42px;
          display: block;
        }
      }

      & ul {
        display: none;
      }
    }

    & .menu {
      display: block;
    }
  }
}

@media (max-width: 474.98px) {
  .header {
    & .links {
      & .logo {
        & #menu-icon {
          margin-right: 42px;
          display: block;
        }
      }

      & ul {
        display: none;
      }
    }

    & .menu {
      & .categories {
        padding-top: 60px;
        height: 690px;
        overflow: visible;

        & .links {
          grid-template-columns: repeat(1, 1fr);
          gap: 50px;

          & .category {
            & .category-img {
              width: 40%;
            }
          }
        }
      }
    }
  }
}
