/* The container */
.radio-container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  border: 1px solid grey;
  border-radius: 10px;
  padding-left: 51px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;

  &:hover {
    border-color: $chocolate;
  }

  &.border-chocolate {
    border-color: $chocolate;
  }

  /* Hide the browser's default radio button */
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* When the radio button is checked, add a blue background */
  & input:checked ~ .checkmark {
    // background-color: #2196f3;
  }

  /* Show the indicator (dot/circle) when checked */
  & input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  & .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $chocolate;
  }
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 20px;
    left: 16px;
    height: 20px;
    width: 20px;
    border: 1px solid grey;
    border-radius: 50%;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
}
