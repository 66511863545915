@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&display=swap");

body,
html,
.btn {
  font-family: "Manrope", BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

body {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
}

h1 {
  font-size: 56px;
  line-height: 58px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h2 {
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

h3 {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 1.15px;
  text-transform: uppercase;
}

h4 {
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h5 {
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
}

h6 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.overline {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 10px;
  text-transform: uppercase;
}

.sub-title {
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}
