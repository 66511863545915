#index {
  position: relative;

  & .hero {
    height: 730px;
    background-image: url("../../assets/home/desktop/image-hero.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 225px;

    & .text {
      color: $white;
      z-index: 99;
      max-width: 375px;

      & .overline {
        color: rgba($white, 0.5);
        margin-bottom: 40px;
      }

      & h1 {
        font-weight: 400;
        margin: 0;
        margin-bottom: 20px;
      }

      & .description {
        color: rgba($white, 0.5);
        margin-bottom: 55px;
      }
    }

    & .hero-image {
      position: absolute;
      top: 0;
      left: 0;

      & img {
        width: 100vw;
      }
    }
  }

  #products {
    margin-top: 165px;

    & .zx9 {
      background-color: $chocolate;
      border-radius: 10px;
      height: 560px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 90px 0 140px;
      margin-bottom: 48px;

      & .text {
        width: 349px;
        color: $white;

        & h1 {
          font-weight: 400;
          margin: 0;
          margin-bottom: 20px;
        }

        & .description {
          margin-bottom: 40px;
        }

        & .btn {
          margin-top: 46px;
        }
      }

      & img {
        bottom: -10px;
        width: 370px;
        margin-bottom: -130px;
      }
    }

    & .zx7 {
      background-image: url("../../assets/home/desktop/image-speaker-zx7.jpg");
      border-radius: 10px;
      height: 320px;
      margin-bottom: 48px;
      padding: 0 100px;
      display: flex;
      align-items: center;

      & h3 {
        font-weight: 400;
        margin: 0;
        margin-bottom: 40px;
      }
    }

    & .yx1 {
      // border-radius: 5px;
      height: 320px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      & .image {
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }

      & .text {
        padding: 0 100px;
        display: flex;
        align-items: center;
        background-color: $grey;
        border-radius: 10px;
        width: 100%;

        & h3 {
          font-weight: 400;
          margin: 0;
          margin-bottom: 40px;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  #index {
    position: relative;

    & .hero {
      height: 730px;
      background-image: url("../../assets/home/desktop/image-hero.jpg");
      background-position: 85%;

      & .text {
        color: $white;
        z-index: 99;
        max-width: 375px;
        margin: 0 auto;
        text-align: center;

        & .overline {
          color: rgba($white, 0.5);
          margin-bottom: 40px;
        }

        & h1 {
          font-weight: 400;
          margin: 0;
          margin-bottom: 20px;
        }

        & .description {
          color: rgba($white, 0.5);
          margin-bottom: 55px;
        }
      }

      & .hero-image {
        position: absolute;
        top: 0;
        left: 0;

        & img {
          width: 100vw;
        }
      }
    }

    #products {
      margin-top: 165px;

      & .zx9 {
        background-color: $chocolate;
        border-radius: 10px;
        height: 600px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        // padding: 0 90px 0 140px;
        padding: 0;
        margin-bottom: 48px;
        text-align: center;

        & .text {
          width: 349px;
          color: $white;
          margin-bottom: 30px;

          & h1 {
            font-weight: 400;
            margin: 0;
            margin-bottom: 20px;
          }

          & .description {
            margin-bottom: 40px;
          }

          & .btn {
            margin-top: 46px;
          }
        }

        & img {
          bottom: 10px;
          width: 172px;
          margin-bottom: 0px;
        }
      }

      & .zx7 {
        background-image: url("../../assets/home/desktop/image-speaker-zx7.jpg");
        border-radius: 10px;
        height: 320px;
        margin-bottom: 48px;
        padding: 0 100px;
        display: flex;
        align-items: center;

        & h3 {
          font-weight: 400;
          margin: 0;
          margin-bottom: 40px;
        }
      }

      & .yx1 {
        height: 320px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;

        & .image {
          border-radius: 10px;
          overflow: hidden;
          width: 100%;
          height: 100%;

          & img {
            height: 100%;
          }
        }

        & .text {
          padding: 0 40px;
          display: flex;
          align-items: center;
          background-color: $grey;
          border-radius: 10px;
          width: 100%;

          & h3 {
            font-weight: 400;
            margin: 0;
            margin-bottom: 40px;
            font-size: 28px;
          }
        }
      }
    }
  }
}

@media (max-width: 474.98px) {
  #index {
    position: relative;

    & .hero {
      height: 730px;
      background-image: url("../../assets/home/desktop/image-hero.jpg");
      background-position: 72%;

      & .text {
        color: $white;
        z-index: 99;
        max-width: 375px;
        margin: 0 auto;
        text-align: center;

        & .overline {
          color: rgba($white, 0.5);
          margin-bottom: 40px;
        }

        & h1 {
          font-weight: 400;
          margin: 0;
          margin-bottom: 20px;
          font-size: 36px;
        }

        & .description {
          color: rgba($white, 0.5);
          margin-bottom: 55px;
        }
      }

      & .hero-image {
        position: absolute;
        top: 0;
        left: 0;

        & img {
          width: 100vw;
        }
      }
    }

    #products {
      margin-top: 165px;

      & .zx9 {
        background-color: $chocolate;
        border-radius: 10px;
        height: 600px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0;
        margin-bottom: 48px;
        text-align: center;

        & .text {
          width: 349px;
          color: $white;
          margin-bottom: 30px;

          & h1 {
            font-weight: 400;
            margin: 0;
            margin-bottom: 20px;
            font-size: 36px;
          }

          & .description {
            margin-bottom: 40px;
          }

          & .btn {
            margin-top: 46px;
          }
        }

        & img {
          bottom: 10px;
          width: 172px;
          margin-bottom: 0px;
        }
      }
      & .zx7 {
        background-image: url("../../assets/home/mobile/image-speaker-zx7.jpg");
        background-position: right;
        background-size: cover;
        border-radius: 10px;
        height: 320px;
        margin-bottom: 48px;
        padding: 0 24px;
        display: flex;
        align-items: center;

        & h3 {
          font-weight: 400;
          margin: 0;
          margin-bottom: 40px;
        }
      }

      & .yx1 {
        height: auto;
        display: flex;
        flex-direction: column;

        & .image {
          border-radius: 10px;
          overflow: hidden;
          width: 100%;
          height: 200px;

          & img {
            width: 100%;
          }
        }

        & .text {
          padding: 0 40px;
          display: flex;
          align-items: center;
          background-color: $grey;
          border-radius: 10px;
          width: 100%;
          height: 200px;

          & h3 {
            font-weight: 400;
            margin: 0;
            margin-bottom: 40px;
            font-size: 28px;
          }
        }
      }
    }
  }
}
