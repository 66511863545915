#best-gear {
  margin-top: 160px;

  & .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    & .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & h2 {
        font-weight: 400;
        max-width: 445px;
      }

      & span {
        color: $chocolate;
      }

      p {
        max-width: 445px;
        color: rgba($black, 0.5);
      }
    }

    & .image {
      overflow: hidden;
      border-radius: 10px;
      height: 588px;
      background-image: url("../../assets/shared/desktop/image-best-gear.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

@media (max-width: 767.98px) {
  #best-gear {
    margin-top: 100px;
    & .content {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      & .text {
        width: 90%;
        text-align: center;

        & h2 {
          max-width: 100%;
        }

        p {
          max-width: 100%;
        }
      }

      & .image {
        height: 300px;
        width: 100%;
        background-image: url("../../assets/shared/tablet/image-best-gear.jpg");

        margin-bottom: 70px;
      }
    }
  }
}

@media (max-width: 474.98px) {
  #best-gear {
    & .content {
      flex-direction: column-reverse;
      align-items: center;

      & .text {
        width: 100%;
        text-align: center;

        & h2 {
          max-width: 100%;
          font-size: 28px;
          margin-bottom: 40px;
        }

        p {
          max-width: 100%;
        }
      }

      & .image {
        height: 300px;
        width: 100%;
        background-image: url("../../assets/shared/mobile/image-best-gear.jpg");

        margin-bottom: 40px;
      }
    }
  }
}
