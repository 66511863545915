.categories {
  margin-top: 200px;

  & .links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    & .category {
      border-radius: 5px;
      background-color: $grey;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 204px;
      padding-bottom: 30px;

      & .category-img {
        width: 200px;
        margin-bottom: -15px;
      }

      & h6 {
        font-weight: 400;
        margin: 0;
        margin-bottom: 20px;
      }

      & a {
        text-transform: uppercase;
        letter-spacing: 2px;
        color: rgba($black, 0.7);
        font-size: 13px;

        & img {
          margin-left: 10px;
        }

        &:hover {
          color: $chocolate;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .categories {
    & .links {
      grid-template-columns: 1fr;
      gap: 90px;
    }
  }
}
