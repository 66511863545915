#category {
  & .category-header {
    background-color: $black;
    height: 336px;
    padding-top: 200px;
    text-align: center;

    & h2 {
      margin: 0;
      color: $white;
      font-weight: 400;
    }
  }

  & .products {
    display: flex;
    flex-direction: column;
    margin-top: 160px;

    & .product {
      height: 560px;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-top: 160px;
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        & .text {
          padding: 0;
          padding-right: 100px;
        }
      }

      & img {
        border-radius: 5px;
        height: 100%;
        width: 49%;
        object-fit: cover;
        object-position: center;
      }

      & .text {
        padding-left: 100px;
        width: 50%;

        & .overline {
          color: $chocolate;
          margin-bottom: 32px;
        }

        & .name {
          font-weight: 400;
          margin-bottom: 32px;
          max-width: 265px;
        }

        & .description {
          color: rgba($black, 0.5);
          margin-bottom: 60px;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  #category {
    & .category-header {
      background-color: $black;
      height: 192px;
      padding-top: 100px;
      text-align: center;

      & h2 {
        margin: 0;
        color: $white;
        font-weight: 400;
        font-size: 40px;
      }
    }

    & .products {
      display: flex;
      flex-direction: column;
      margin-top: 100px;

      & .product {
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        &:not(:first-child) {
          margin-top: 160px;
        }

        &:nth-child(even) {
          flex-direction: column;

          & .text {
            padding: 0;
          }
        }

        & img {
          border-radius: 5px;
          width: 100%; //
          object-fit: cover;
          object-position: center;
          margin-bottom: 50px;
        }

        & .text {
          padding: 0;
          width: 100%;
          text-align: center;

          & .overline {
            color: $chocolate;
            margin-bottom: 32px;
          }

          & .name {
            font-weight: 400;
            max-width: 100%;
            margin-right: 0;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 40px;
            font-size: 28px;
          }

          & .description {
            color: rgba($black, 0.5);
            margin-bottom: 60px;
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}
@media (max-width: 424.98px) {
  #category {
    & .category-header {
      padding-top: 120px;

      & h2 {
        font-size: 28px;
      }
    }

    & .products {
      margin-top: 64px;

      & .product {
        & .text {
          & .description {
            max-width: 100%;
          }
        }
      }
    }
  }
}
