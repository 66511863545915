form {
  & .input-group {
    display: flex;
    flex-direction: column;

    & label {
      margin-bottom: 8px;
      font-size: 12px;
    }

    & input {
      padding: 24px;
      border-radius: 5px;
      border: 1px solid #cfcfcf;
      caret-color: $chocolate;
      position: relative; //

      &::before {
        content: "Wrong format";
        color: red;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        height: 20px;
        width: 60px;
        background-color: aqua;
      }

      &::placeholder {
        color: #cfcfcf;
      }

      &:active,
      &:focus,
      &:focus-visible {
        outline-color: $chocolate;
      }

      &:invalid {
        border-color: red;
        outline: red;
      }
    }
  }

  & .billing-details,
  & .shipping-info,
  & .payment-details {
    & .inputs {
      margin-top: 32px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }

  & .shipping-info {
    & .inputs {
      & div:nth-child(1) {
        grid-row-start: 0;
        grid-column: span 2;
      }
    }
  }

  & .form-section-head {
    color: chocolate;
    margin-top: 56px;
    margin-bottom: 32px;
  }

  & .emoney-checked {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

@media (max-width: 474.98px) {
  form {
    & .input-group {
      display: flex;
      flex-direction: column;

      & label {
        margin-bottom: 8px;
        font-size: 12px;
      }

      & input {
        padding: 24px;
        border-radius: 5px;
        border: 1px solid #cfcfcf;

        &::placeholder {
          color: #cfcfcf;
        }

        &:active,
        &:focus,
        &:focus-visible {
          outline-color: $chocolate;
        }

        &:invalid {
          border-color: red;
          outline: red;
        }
      }
    }

    & .billing-details,
    & .shipping-info,
    & .payment-details {
      & .inputs {
        margin-top: 32px;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        display: flex;
        flex-direction: column;
      }
    }

    & .shipping-info {
      & .inputs {
        & div:nth-child(1) {
          grid-row-start: 0;
          grid-column: span 2;
        }
      }
    }

    & .form-section-head {
      color: chocolate;
      margin-top: 56px;
      margin-bottom: 32px;
    }

    & .emoney-checked {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }
}
