* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px;
}

a {
  text-decoration: none;
}

@media (max-width: 767.98px) {
  .container {
    max-width: auto;
    margin: 0 auto;
    padding: 0 30px;
  }
}
@media (max-width: 474.98px) {
  .container {
    max-width: auto;
    margin: 0 auto;
    padding: 0 16px;
  }
}
